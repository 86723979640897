import { graphql, Link, navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import useForm from 'react-hook-form'
import { findClientByNIF } from '../../api/user'
import EmailIcon from '../../assets/images/register/email.svg'
import Cita from '../../assets/images/register/icon_circulo_cita.svg'
import Control from '../../assets/images/register/icon_control.svg'
import SelectorFooterDesktop from '../../components/FunnelSelector/Footers/SelectorFooterDesktop'
import FunnelSideBar from '../../components/funnelSideBar/FunnelSideBar'
import Layout from '../../components/Layout'
import LayoutSelector from '../../components/layouts/layoutSelector/LayoutSelector'
import RegisterStepTwo from '../../components/registerForm/fullRegister/step2/RegisterStepTwo'
import RegisterStepThree from '../../components/registerForm/fullRegister/step3/RegisterStepThree'
import RegisterContainer from '../../components/registerForm/RegisterContainer'
import Tabs from '../../components/tabs/Tabs'
import VehicleFinder from '../../components/vehicleFinder/VehicleFinder'
import { storeUserData, transformData } from '../../context/actions/userActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import { SeoData } from '../../types/Seo'
import route from '../../utils/route'
import styles from './index.module.scss'

import {
  RegisterDocumentData,
  RegisterDocumentSchema,
} from '../../helpers/schemas/RegisterDocumentSchema'

const tabsArray = [
  { tab: t('steps.signin'), route: route('register.index'), active: true },
  { tab: t('steps.login'), route: route('login.index'), active: false },
]

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function Register({ data }: Props) {
  const [step, setStep] = useState(1)
  const [globalError, setGlobalError] = useState(false as boolean | string)
  const [loading, setLoading] = useState(false)
  const { userInfo, dispatch } = useContext(AppContext)
  const dniForm = useForm<RegisterDocumentData>({
    mode: 'onChange',
    validationSchema: RegisterDocumentSchema,
  })

  const onGoToNextStep = () => {
    setStep((step) => step + 1)
  }

  const onGoToPreviousStep = () => {
    if (step === 1) return navigate(route('index'))
    setStep((step) => step - 1)
  }

  const onRegisterSuccess = () => {
    onGoToNextStep()
  }

  const onLoginSuccess = () => {
    return navigate(route('area-privada.index'))
  }

  const onSubmitDni = async (data: RegisterDocumentData) => {
    const {
      document: { documentCountry, documentType, documentValue } = {},
      dniRadio,
    } = data
    if (dniRadio === 'no') {
      return onGoToNextStep()
    }
    try {
      setGlobalError(null)
      setLoading(true)
      const res = await findClientByNIF(
        documentValue,
        documentType,
        documentCountry,
        userInfo.idUser
      )
      dispatch(storeUserData(transformData(res)))
      setLoading(false)
      onGoToNextStep()
    } catch (err) {
      setLoading(false)
      if (err.message === 'DNI_USED') {
        setGlobalError(t('registerForm.error_dni_existe'))
      }
    }
  }

  return (
    <Layout
      seoData={data.seoData}
      onGoBack={onGoToPreviousStep}
      selector={true}>
      <LayoutSelector
        hideSelectorSteps={true}
        title={t('steps.area_clientes')}
        subTitle={[1, 2].includes(step) ? t('home.register') : ''}
        showCopyrightFooter>
        {step === 1 && (
          <div className={styles.register_form_container}>
            <Tabs tabsArray={tabsArray} />
            <RegisterContainer
              hideLoginBar
              onLoginSuccess={onLoginSuccess}
              onRegisterSuccess={onRegisterSuccess}
            />
          </div>
        )}

        {step === 2 && (
          <div
            className={`${styles.register_form_container} ${styles.taller_visited}`}>
            <form onSubmit={dniForm.handleSubmit(onSubmitDni)}>
              <RegisterStepTwo
                loading={loading}
                globalError={globalError}
                form={dniForm}
              />
              <SelectorFooterDesktop
                onGoBack={onGoToPreviousStep}
                continueButton
              />
            </form>
          </div>
        )}
        {step === 3 && (
          <div className={`${styles.register_form_container} ${styles.step3}`}>
            <RegisterStepThree />
            <div className={styles.vehicle_finder_wrapper}>
              <VehicleFinder origin="registro" description=" " />
            </div>
          </div>
        )}
        {[1, 2].includes(step) ? (
          <FunnelSideBar>
            <p>{t('sidebar.register.por_que_registrarse')}</p>
            <div className={styles.sidebar_item}>
              <div className={styles.icon_circle}>
                <EmailIcon />
              </div>
              <div className={styles.sidebar_item_text}>
                <p className={styles.sidebar_title}>
                  {t('sidebar.register.item_one_title')}
                </p>
                <p>{t('sidebar.register.item_one_description')}</p>
              </div>
            </div>

            <div className={styles.sidebar_item}>
              <div className={styles.icon_circle}>
                <Cita />
              </div>
              <div className={styles.sidebar_item_text}>
                <p className={styles.sidebar_title}>
                  {t('sidebar.register.item_two_title')}
                </p>
                <p>{t('sidebar.register.item_two_description')}</p>
              </div>
            </div>

            <div className={styles.sidebar_item}>
              <div className={styles.icon_circle}>
                <Control />
              </div>
              <div className={styles.sidebar_item_text}>
                <p className={styles.sidebar_title}>
                  {t('sidebar.register.item_three_title')}
                </p>
                <p>{t('sidebar.register.item_three_description')}</p>
              </div>
            </div>
          </FunnelSideBar>
        ) : (
          <React.Fragment>
            <FunnelSideBar />
            <div className={styles.more_info}>
              <p>{t('registerForm.interesting.title')}</p>
              <ul>
                <li className={styles.list_item}>
                  <Link to={route('area-privada.index')}>
                    {t('registerForm.interesting.item_one')}
                  </Link>
                </li>
                <li className={styles.list_item}>
                  <Link to={route('revisiones.index')}>
                    {t('registerForm.interesting.item_two')}
                  </Link>
                </li>
                <li className={styles.list_item}>
                  <Link to={route('empresa.index')}>
                    {t('registerForm.interesting.item_three')}
                  </Link>
                </li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </LayoutSelector>
    </Layout>
  )
}

export const pageQuery = graphql`
  query registerPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
