import React from 'react'
import { FormattedMessage } from 'react-intl'
import Done from '../../../Done/Done'
import styles from './index.module.scss'

export default function RegisterStepThree() {
  return (
    <React.Fragment>
      <div className={styles.successful_register_container}>
        <div className={styles.area_clientes_text}>
          <FormattedMessage id="steps.area_clientes" />
        </div>
        <div className={styles.header}>
          <Done className={styles.confirmation_icon} />
          <h2 className={styles.title}>
            <FormattedMessage id="registrado.gracias" />
          </h2>
        </div>
      </div>
      <div className={styles.aprovecha_box}>
        <p>
          <FormattedMessage id="registrado.aprovecha" />
        </p>
      </div>
    </React.Fragment>
  )
}
