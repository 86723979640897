import React, { useEffect } from 'react'
import { FieldErrors } from 'react-hook-form/dist/types'
import { t } from '../../../i18n'
import Button from '../../buttons/Button'
import FormError from '../../FormError/FormError'
import InfoIcon from '../../infoIcon/InfoIcon'
import { MatriculaData } from '../VehicleFinder'
import { VehicleFinderOrigin } from '../VehicleFinderTypes'
import styles from './index.module.scss'
import dataLayer from '../../../helpers/functions/dataLayer'

interface StepOneProps {
  handleSubmit: any
  onSubmit: (data: object, event: React.ChangeEvent<HTMLSelectElement>) => void
  formRef: any
  errors: FieldErrors<MatriculaData>
  loading: boolean
  origin: VehicleFinderOrigin
  description: any
}
export default function VehicleFinderStepOne({
  handleSubmit,
  onSubmit,
  formRef,
  errors,
  loading,
  origin,
  description,
}: StepOneProps) {
  const isAreaPrivada = origin === 'privada'

  useEffect(() => {
    dataLayer.push({
      event: 'page_view',
      route: '/alta-vehiculo/matricula',
      title: document.title,
      section: origin,
    })
  }, [])

  if (!loading) {
    return (
      <div className={styles.reserva_input}>
        <p className={styles.registra}>{description}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputLine}>
            <label className={styles.tag}>
              {t('cesta.reserva.registra_label')}
              <InfoIcon text={t('alta_vehiculo.tooltip_matricula')} />
            </label>
            <input
              className={styles.input}
              name="matricula"
              ref={formRef}></input>
          </div>
          <p className={styles.aviso_mobile}>{t('cesta.reserva.aviso')}</p>
          <div
            className={
              isAreaPrivada
                ? styles.desktop_btn_container_area_privada
                : styles.desktop_btn_container
            }>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              buttonClass="next_button"
              type="submit">
              {t('cesta.reserva.registra_button')}
            </Button>
          </div>
        </form>
        <div className={styles.errors_container}>
          <FormError errors={errors} field="matricula" />
        </div>
        <p className={styles.aviso_desktop}>{t('cesta.reserva.aviso')}</p>
        {isAreaPrivada && (
          <div className={styles.mobile_btn_container}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              buttonClass="rodi_full_width">
              {t('cesta.reserva.registra_button')}
            </Button>
          </div>
        )}
      </div>
    )
  }
  return null
}
