import React, { useState } from 'react'
import { t } from '../../../../i18n'
import Error from '../../../error/Error'
import FormError from '../../../FormError/FormError'
import RadioButtonYellow from '../../../radioButtonYellow/RadioButtonYellow'
import SpinnerWithText from '../../../spinnerWithText/SpinnerWithText'
import styles from './index.module.scss'
import DocumentInputRow from '../../../DocumentInputRow/DocumentInputRow'
import { FormMethods } from '../../../../types/FormMethods'
import { RegisterDocumentData } from '../../../../helpers/schemas/RegisterDocumentSchema'

interface Props {
  globalError: string | boolean
  loading?: boolean
  form: FormMethods<RegisterDocumentData>
}
export default function RegisterStepTwo({ globalError, loading, form }: Props) {
  const [visitedRodi, setVisitedRodi] = useState(null)
  const [showDniInput, setShowDniInput] = useState(false)

  const handleRadioButton = (value: string): void => {
    setVisitedRodi(value)
    if (value === 'yes') {
      setShowDniInput(true)
    } else {
      setShowDniInput(false)
    }
  }
  if (loading) {
    return <SpinnerWithText text={t('login.cargando')} />
  }

  const { register, errors } = form

  return (
    <div className={styles.visited_rodi_container}>
      <p>{t('registerForm.visitado_taller')}</p>
      <div className={styles.checkboxes_container}>
        <RadioButtonYellow
          onChange={handleRadioButton}
          checked={visitedRodi === 'yes'}
          label1={
            <div className={styles.label_title}>{t('registerForm.si')}</div>
          }
          name="dniRadio"
          formRef={register}
          value="yes"
        />
        {showDniInput && (
          <div className={styles.dni_container}>
            <div className={styles.dni_text}>{t('facturacion_form.nif')}:</div>
            <DocumentInputRow form={form} />
          </div>
        )}
        <RadioButtonYellow
          onChange={handleRadioButton}
          checked={visitedRodi === 'no'}
          label1={
            <div className={styles.label_title}>{t('registerForm.no')}</div>
          }
          name="dniRadio"
          formRef={register}
          value="no"
        />
        {globalError && typeof globalError === 'string' && (
          <Error message={globalError}></Error>
        )}
        <FormError errors={errors} field="dniRadio" />
      </div>
    </div>
  )
}
