import * as yup from 'yup'
import { DocumentData, DocumentSchema } from './DocumentSchema'
import { t } from '../../i18n'

export interface RegisterDocumentData {
  dniRadio?: 'yes' | 'no'
  document?: DocumentData
}

export const RegisterDocumentValidations = {
  dniRadio: yup
    .string()
    .oneOf(['yes', 'no'], t('validations.register.options_required')),
  document: yup.object().when('dniRadio', {
    is: (dniRadio) => dniRadio === 'yes',
    then: DocumentSchema,
    otherwise: yup.object().nullable(),
  }),
}
export const RegisterDocumentSchema = yup
  .object()
  .shape(RegisterDocumentValidations)
