import React, { useContext, useState } from 'react'
import useForm from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import * as AltaVehiculoActions from '../../context/actions/altaVehiculoActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import StandardModal from '../modal/standardModal/StandardModal'
import styles from './index.module.scss'
import VehicleFinderStepFive from './vehicleFinderStepFive/VehicleFinderStepFive'
import VehicleFinderStepFour from './vehicleFinderStepFour/VehicleFinderStepFour'
import VehicleFinderStepOne from './vehicleFinderStepOne/VehicleFinderStepOne'
import VehicleFinderStepSix from './vehicleFinderStepSix/VehicleFinderStepSix'
import VehicleFinderStepTwo from './vehicleFinderStepTwo/VehicleFinderStepTwo'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import { matricula, normalizeMatricula } from '../../constants/matricula'
import { VehicleFinderOrigin } from './VehicleFinderTypes'

const MatriculaSchema = yup.object().shape({
  ...matricula,
})
export interface MatriculaData {
  matricula: string
}

interface VehicleFinderProps {
  retrievedVehicleData?: (vehicleData: object) => void
  origin: Exclude<VehicleFinderOrigin, 'revision'>
  description?: string
}

export default function VehicleFinder({
  retrievedVehicleData,
  origin,
  description,
}: VehicleFinderProps) {
  const isAreaPrivada = origin === 'privada'
  const [showModal, setshowModal] = useState(false)
  const { register, handleSubmit, errors } = useForm<MatriculaData>({
    mode: 'onSubmit',
    validationSchema: MatriculaSchema,
  })

  const { altaVehiculoState, dispatch } = useContext(AppContext)

  const { step, loading } = altaVehiculoState

  const handleModal = () => {
    if (!isAreaPrivada) {
      setshowModal(true)
    }
  }

  const onSubmit = (data) => {
    handleModal()
    data.matricula = normalizeMatricula(data.matricula)
    dispatch(AltaVehiculoActions.retrieveVehicleInfo(data.matricula))
  }

  const clearFormErrors = (obj) => {
    for (const property in obj) delete obj[property]
  }

  const closeModal = () => {
    clearFormErrors(errors)
    dispatch(AltaVehiculoActions.setVehicleInfo(null))
    dispatch(AltaVehiculoActions.setMatricula(null))
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(isAreaPrivada ? 1 : 0))
    setshowModal(false)
  }

  const goToNextStep = () => {
    const nextStep = step + 1
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(nextStep))
  }

  const goToStep = (step, foundVehicles) => {
    if (foundVehicles) {
      dispatch(AltaVehiculoActions.setVehicleInfo(foundVehicles))
      dispatch(AltaVehiculoActions.setVehicleImages({}))
      dispatch(AltaVehiculoActions.setInfoFrom('database'))
    }
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(step))
  }
  return (
    <React.Fragment>
      <div>
        {step !== 0 && (
          <button
            className={styles.vehicle_finder_btn}
            onClick={() => setshowModal(true)}>
            + {t('alta_vehiculo.add_vehicle_title')}
          </button>
        )}
      </div>
      {step === 0 && (
        <VehicleFinderStepOne
          origin={origin}
          description={description || t('alta_vehiculo.descripccion1')}
          loading={loading}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          formRef={register}
          errors={errors}
        />
      )}
      <StandardModal
        modalClass={styles.modal_vehicle_finder}
        closeModal={closeModal}
        showModal={showModal}
        title={
          step === 1
            ? t('alta_vehiculo.add_vehicle_title')
            : step > 1
            ? t('alta_vehiculo.select_vehicle_title')
            : ''
        }>
        {altaVehiculoState.loading && (
          <SpinnerWithText text={t('alta_vehiculo.buscando')} />
        )}
        {step === 1 && (
          <VehicleFinderStepOne
            origin={origin}
            description={t('alta_vehiculo.descripccion1')}
            loading={loading}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            formRef={register}
            errors={errors}
          />
        )}
        {step === 2 && (
          <VehicleFinderStepTwo
            origin={origin}
            goToStep={goToStep}
            closeModal={closeModal}
            retrievedVehicleData={retrievedVehicleData}
          />
        )}
        {!altaVehiculoState.loading && step === 4 && (
          <VehicleFinderStepFour origin={origin} goToStep={goToStep} />
        )}
        {step === 5 && (
          <VehicleFinderStepFive
            origin={origin}
            goToStep={goToStep}
            goToNextStep={goToNextStep}
          />
        )}
        {step === 6 && <VehicleFinderStepSix origin={origin} />}
      </StandardModal>
    </React.Fragment>
  )
}
